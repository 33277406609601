import {
  request
} from "../_helpers";

export const online_monitoring_full = {
  namespaced: true,
  state: {
    data: [],
    ddos_summary: true,
    active_platform: {
      active: false
    },
    failure: {
      active: false
    },
    problems: {
      active: false
    },
    large_garbage: {
      active: false
    },
    pick_up: {
      active: false
    },
    cancelation_list: {
      active: false
    },
    large_garbage_out: {
      active: false
    },
    delay: {
      active: false
    },
    points_work_order: null,
    opacity_points_work_order: null,
    trackStop: {
      active: false,
    },
    incidents_length: null,
    om_loading: false,
  },
  getters: {
    all_Items: state => {
      return state.data
    },
    all_Platforms: state => {
      if (state.data.wos) {
        return state.data.wos.data.map(e => e.platforms.map(item => {item.waste_type = e.waste_type? e.waste_type: {}; return item})).flat();
      }
    },
    all_work_orders: state => {
      if (state.data.wos) {
        return state.data.wos.data.map(e => {
          return {
            id: e.id,
            name: e.vehicle.name,
            work_order_name: e.name,
            waste_type: e.waste_type,
          };
        })
      }
    },
    points_work_order: state => {
      return state.points_work_order;
    },
    opacity_points_work_order: state => {
      return state.opacity_points_work_order;
    },
    active_platform: state => {
      return state.active_platform;
    },
    incident_failure: state => {
      return state.failure;
    },
    incident_problems: state => {
      return state.problems;
    },
    incident_large_garbage: state => {
      return state.large_garbage;
    },
    incident_pick_up: state => {
      return state.pick_up;
    },
    incident_cancelation_list: state => {
      return state.cancelation_list;
    },
    incident_large_garbage_out: state => {
      return state.large_garbage_out;
    },
    incident_delay: state => {
      return state.delay;
    },
    trackStop: state => {
      return state.trackStop;
    },
    incidents_length: state => {
      return state.incidents_length;
    },
    om_loading: state => {
      return state.om_loading
    }
  },
  actions: {
    all_Items(store, payload) {  
      if (store.state.ddos_summary) {
        store.commit('om_loading', true);

        request(`${process.env.VUE_APP_WN}/fact/workorder/summary?o=${payload.organisation_id}&date=${payload.filterTime}`, "get").then((res) => {
          if (res.success) {
            if (res.data) {
              store.commit('all_Items', res.data);
              store.commit('ddos_summary', true);
              store.commit('om_loading', false);
            }
          }
        }).catch(()=> {store.commit('ddos_summary', true); store.commit('om_loading', false);});
      }
      
      store.commit('ddos_summary', false);
    },
    points_work_order(store, workorder_id) {
      store.commit('points_work_order', workorder_id);
    },
    opacity_points_work_order(store, workorder_id) {
      store.commit('opacity_points_work_order', workorder_id);
    },
    active_platform(store, platform) {
      //Если делаем диспатч без platform_id, то окно закрывается, дальше отслеживаем изменение ключа load, в зависимости от статуса пишем уведомления
      if (platform) {
        store.commit('active_platform', {active: true, load: "load"});
        request(
          `${process.env.VUE_APP_WN}/fact/platform/${platform.platform_id}`,
          "get"
        ).then((res) => {
          if (res.success) {
            store.commit('active_platform', {active: true, load: "unload", data: res.data, co: platform.co, platform_id: platform.platform_id, workorder_id: res.data.wo});
          } else {
            store.commit('active_platform', {active: true, load: "problem"})
          }
        });
      } else {
        store.commit('active_platform', {active: false});
      }
    },
    incident_failure(store, payload) {
      if (payload) {
        store.commit('incident_failure', {active: true, load: 'load'});
        const isConfirmation = typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null;
        request(
          `${process.env.VUE_APP_WN}/fact/failure?o=${payload.organisation_id}&page=all&date=${payload.filter_time}&waste_type_ids=${payload.waste_type_ids}&is_confirmation=${isConfirmation}`,
          'get'
        ).then((res) => {
          if (res.success) {
            store.commit('incident_failure', {
              active: true,
              load: 'unload',
              data: res.data,
            });
          } else {
            store.commit('incident_failure', { active: true, load: 'problem' });
          }
        });
      } else {
        store.commit('incident_failure', {active: false});
      }
    },
    incident_problems(store, payload) {
      if (payload) {
        store.commit('incident_problems', {active: true, load: 'load'});
        const isConfirmation =
          typeof payload.is_confirmation === 'boolean'
            ? payload.is_confirmation
            : null;
        request(
          `${process.env.VUE_APP_WN}/fact/breakdown?o=${payload.organisation_id}&page=all&date=${payload.filter_time}&waste_type_ids=${payload.waste_type_ids}&is_confirmation=${isConfirmation}`,
          'get'
        ).then((res) => {
          if (res.success) {
            store.commit('incident_problems', {
              active: true,
              load: 'unload',
              data: res.data,
            });
          } else {
            store.commit('incident_problems', {
              active: true,
              load: 'problem',
            });
          }
        });
      } else {
        store.commit('incident_problems', {active: false});
      }
    },
    incident_large_garbage(store, payload) {
      if (payload) {
        store.commit('incident_large_garbage', {active: true, load: 'load'});

        request(`${process.env.VUE_APP_WN}/fact/kgo_list`, 'post', {
          o: payload.organisation_id,
          page: 'all',
          date: payload.filter_time,
          waste_type_ids: payload.waste_type_ids,
          is_confirmation: typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null,
        })
        .then(res => {
          if (res.success) {
            store.commit('incident_large_garbage', {active: true, load: 'unload', data: res.data});
          } else {
            store.commit('incident_large_garbage', {active: true, load: 'problem'});
          }
        })
      } else {
        store.commit('incident_large_garbage', {active: false});
      }
    },
    incident_pick_up(store, payload) {
      if (payload) {
        store.commit('incident_pick_up', {active: true, load: 'load'});

        request(`${process.env.VUE_APP_WN}/fact/pickup_list`, 'post', {
          o: payload.organisation_id,
          page: 'all',
          date: payload.filter_time,
          waste_type_ids: payload.waste_type_ids,
          is_confirmation: typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null,
        })
        .then(res => {
          if (res.success) {
            store.commit('incident_pick_up', {active: true, load: 'unload', data: res.data});
          } else {
            store.commit('incident_pick_up', {active: true, load: 'problem'});
          }
        })
      } else {
        store.commit('incident_pick_up', {active: false});
      }
    },
    incident_cancelation_list(store, payload) {
      if (payload) {
        store.commit('incident_cancelation_list', {active: true, load: 'load'});
        request(
          `${process.env.VUE_APP_WN}/fact/failure/cancellation_list`,
          'post',
          {
            o: payload.organisation_id,
            page: 'all',
            date: payload.filter_time,
            waste_type_ids: payload.waste_type_ids,
            is_confirmation: payload.is_confirmation,
            is_confirmation: typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null,
          }
        ).then((res) => {
          if (res.success) {
            store.commit('incident_cancelation_list', {
              active: true,
              load: 'unload',
              data: res.data,
            });
          } else {
            store.commit('incident_cancelation_list', {
              active: true,
              load: 'problem',
            });
          }
        });
      } else {
        store.commit('incident_cancelation_list', {active: false});
      }
    },
    incident_large_garbage_out(store, payload) {
      if (payload) {
        store.commit('incident_large_garbage_out', {active: true, load: 'load'});

        request(`${process.env.VUE_APP_WN}/fact/remaining_kgo_list`, 'post', {
          o: payload.organisation_id,
          page: 'all',
          date: payload.filter_time,
          waste_type_ids: payload.waste_type_ids,
          is_confirmation: typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null,
        }).then((res) => {
          if (res.success) {
            store.commit('incident_large_garbage_out', {
              active: true,
              load: 'unload',
              data: res.data,
            });
          } else {
            store.commit('incident_large_garbage_out', {
              active: true,
              load: 'problem',
            });
          }
        });
      } else {
        store.commit('incident_large_garbage_out', {active: false});
      }
    },
    incident_delay(store, payload) {
      store.commit('incident_delay', { active: true, load: 'load' });
      const promise1 = request(
        `${process.env.VUE_APP_WN}/fact/delay_list`,
        'post',
        {
          o: payload.organisation_id,
          page: 'all',
          date: payload.filter_time,
          waste_type_ids: payload.waste_type_ids,
          is_confirmation: typeof payload.is_confirmation === 'boolean' ? payload.is_confirmation : null,
        }
      );
      const promise2 = request(`${process.env.VUE_APP_WN}/fact/planned_delay_list`, 'post', {
        o: payload.organisation_id,
        page: 'all',
        date: payload.filter_time,
        waste_type_ids: payload.waste_type_ids
      })
      Promise.all([promise1, promise2])
        .then((responses) => {
          if (responses[0].success && responses[1].success) {
            store.commit('incident_delay', { active: true, load: 'unload', data: {
              delay: responses[0].data,
              planned_delay: responses[1].data,
            }});
          }
        })

    },
    incidents_length(store, payload) {
      if (payload) {
        store.dispatch('reset_incidents', payload.reset);
        const bodyForRequest = {
          o: payload.organisation_id,
          page: 'all',
          date: payload.filter_time,
          waste_type_ids: payload.waste_type_ids,
        };

        if (payload.department_ids) {
          bodyForRequest.department_ids = payload.department_ids;
        }
          request(
            `${process.env.VUE_APP_WN}/fact/summary/counter_list`,
            'post', bodyForRequest).then((res) => {
            if (res.success) {
              store.commit('incidents_length', res.data);
            }
          });
      } else {
        store.dispatch('reset_incidents')
      }
    },
    reset_incidents(store, noreset) {
      let list_incidents = [
        'incident_cancelation_list',
        'incident_pick_up',
        'incident_large_garbage',
        'incident_problems',
        'incident_failure',
        'incident_large_garbage_out',
        'incident_delay',
      ];
      
      for (const i of list_incidents) {
        if (i != noreset) {
          store.commit(i, {active: false});
        }
      }
    },
    trackStop(store, id) {
      if (id) {
        store.commit('trackStop', {active: true, load: 'load'});

        request(`${process.env.VUE_APP_WN}/fact/track_list`, "post",
        {
          workorder_id: id
        })
        .then(res => {
          if (res.success) {
            store.commit('trackStop', {active: true, load: 'unload', id, data: res.data});
          } else {
            store.commit('trackStop', {active: true, load: 'problem', id});
          }
        })
      } else {
        store.commit('trackStop', {active: false});
      }
    },
  },
  mutations: {
    ddos_summary(store, payload) {
      store.ddos_summary = payload;

    },
    all_Items(store, items) {
      store.data = items;
    },
    points_work_order(store, workorder_id) {
      store.points_work_order = workorder_id.work_order? store.data.wos?.data.find(e => e.id == workorder_id.work_order): null;
    },
    opacity_points_work_order(store, workorder_id) {
      //Для добавления прозрачности при выборе задания, поскольку мы мутируем основной массив данных, возвращение null не даст никакого эффекта, 
      //в данных будет opacity по последнему применению выбора задания и если выбрать пункт "№ Задания, машина" обнуления не будет, поэтому перезаписываем на нужные данные
      store.opacity_points_work_order = store.data.wos?.data.map(e => {
        for (const item of e.platforms) {
          if (workorder_id.work_order) {
            if (e.id == workorder_id.work_order) {
              item.opacity = 1
            } else {
              item.opacity = 0.2 
            }
          } else {
            item.opacity = 1
          }
        }
        
        return e.platforms
      }).flat();
    },
    active_platform(store, item) {
      store.active_platform = item;
    },
    incident_failure(store, items) {
      store.failure = items;
    },
    incident_problems(store, items) {
      store.problems = items;
    },
    incident_large_garbage(store, items) {
      store.large_garbage = items;
    },
    incident_pick_up(store, items) {
      store.pick_up = items;
    },
    incident_cancelation_list(store, items) {
      store.cancelation_list = items;
    },
    incident_large_garbage_out(store, items) {
      store.large_garbage_out = items;
    },
    incident_delay(store, items) {
      store.delay = items;
    },
    incidents_length(store, items) {
      store.incidents_length = items;
    },
    trackStop(store, items) {
      store.trackStop = items;
    },
    om_loading(store, condition) {
      store.om_loading = condition;
    }
  }
};