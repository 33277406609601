<template>
  <div class="gserver-links" :class="{'gserver-links--active': this.$route.meta.active == 9}">
    <img
      class="normal"
      src="@/assets/restyle/images/menu/gserver.svg"
      alt=""
      style="width: 24px; margin-right: 17px;"
      @mouseenter="setIsVisibleNotPopup(false)"
    />
    <div class="hover">
      <img
        src="@/assets/restyle/images/menu/gserver_green.svg"
        alt=""
        style="width: 24px; margin-right: 17px;"
      />
    </div>
    <div class="links-block">
      <div class="links-block-link" v-if="back_office">
        <router-link :to="{ name: 'vehicle_sensor' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('vehicle_sensor') }}
          <div v-if="isActiveHotSpot('vehicle_sensor')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('vehicle_sensor'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="back_office">
        <router-link
          :to="{ name: 'all_list_sensor_components' }"
          class="links-item"
          >
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('all_list_sensor_components') }}
          <div v-if="isActiveHotSpot('all_list_sensor_components')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('all_list_sensor_components'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link">
        <router-link :to="{ name: 'command_gserver' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('command_gserver') }}
          <div v-if="isActiveHotSpot('command_gserver')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('command_gserver'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    back_office: {
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
  },
  methods: {
    ...mapMutations({
        setIsVisibleNotPopup: "user_notifications/SET_IS_VISIBLE_NOTIFICATIONS_POPUP",
    }),
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  }
}

</script>

<style lang="scss" scoped>
.links-block {
  padding: 20px 25px;
  z-index: 1;
  display: none;
  row-gap: 20px;
  position: absolute;
  top: 52px;
  right: -30px;
  width: 320px;
  border-radius: 5px;

  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 280px;
    height: 100%;
    background: #1D407B;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
}

  .gserver-links {
    margin-right: 15px;
    position: relative;
    display: flex;
    cursor: pointer;
    height: 100%;
    margin-top: 1px;

    & .hover {
      display: none;
    }

    &:hover {
      & .normal {
        display: none;
      }
      & .hover {
        display: inline-flex;
      }

      & .links-block {
        display: grid;
      }
    }
    &--active {
      & .normal {
        display: none;
      }
      & .hover {
        display: inline-flex;
      }
    }
  }
  
  .link-flex {
    display: flex;
    align-items: center
  }

  .hover {
    &::after {
      content: "";
      left: -250px;
      top: 13px;
      width: 280px;
      height: 40px;
      position: absolute;
    }
  }
</style>