var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block scrollbar scrollbar--secondary"},[(
      _vm.userCan('role_read_all') && (_vm.userCan('admin') || _vm.userCan('superadmin'))
    )?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-role"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'role_all' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('role_all'))+" "),(_vm.isActiveHotSpot('role_all')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('role_all'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(
      _vm.userCan('organisation_read_all') &&
      (_vm.userCan('admin') || _vm.userCan('superadmin'))
    )?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-organisation"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'organisations' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('organisations'))+" "),(_vm.isActiveHotSpot('organisations')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('organisations'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(
      _vm.userCan('user_read_all') && (_vm.userCan('admin') || _vm.userCan('superadmin'))
    )?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-users"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'user_all' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('user_all'))+" "),(_vm.isActiveHotSpot('user_all')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('user_all'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('container_type_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-container_type"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'container_type' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('container_type'))+" "),(_vm.isActiveHotSpot('container_type')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('container_type'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(
      _vm.userCan('container_type_read_all') ||
      _vm.userCan('container_platform_type_read_all') ||
      _vm.userCan('failure_reason_read_all') ||
      _vm.userCan('breakdowns_type_read_all') ||
      _vm.userCan('payment_category_name_read_all')
    )?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-waste_type"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'garbage_type' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('garbage_type'))+" "),(_vm.isActiveHotSpot('garbage_type')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('garbage_type'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('container_platform_type_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-type_platforms"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'platform_type' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('platform_type'))+" "),(_vm.isActiveHotSpot('platform_type')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('platform_type'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('failure_reason_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-failure_reason"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'failure_reason' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('failure_reason'))+" "),(_vm.isActiveHotSpot('failure_reason')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('failure_reason'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('work_order_cancelation_reason_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-cancelation_reason"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'early completion' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('early completion'))+" "),(_vm.isActiveHotSpot('early completion')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('early completion'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('breakdowns_type_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-breakdowns_type"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'breakdown_type' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('breakdown_type'))+" "),(_vm.isActiveHotSpot('breakdown_type')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('breakdown_type'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(
      _vm.userCan('container_type_read_all') ||
      _vm.userCan('container_platform_type_read_all') ||
      _vm.userCan('failure_reason_read_all') ||
      _vm.userCan('breakdowns_type_read_all') ||
      _vm.userCan('payment_category_name_read_all')
    )?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-cancel_reason"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'weight_cancel_reason' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('weight_cancel_reason'))+" "),(_vm.isActiveHotSpot('weight_cancel_reason')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('weight_cancel_reason'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('superadmin') || _vm.userCan('admin'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-list_sensor_containers"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'list_sensor_containers' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('list_sensor_containers'))+" "),(_vm.isActiveHotSpot('list_sensor_containers')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('list_sensor_containers'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('superadmin') || _vm.userCan('admin') || _vm.userCan('role_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-reasons_stopped_container"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'reasons_stopped_container' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('reasons_stopped_container'))+" "),(_vm.isActiveHotSpot('reasons_stopped_container')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('reasons_stopped_container'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('superadmin') || _vm.userCan('claim_reasons_show'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-reasons_stopped_container"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'reasons_for_the_application' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('reasons_for_the_application'))+" "),(_vm.isActiveHotSpot('reasons_for_the_application')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('reasons_for_the_application'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('monitoring_systems_show'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-navigation_systems"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'navigation_systems' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('navigation_systems'))+" "),(_vm.isActiveHotSpot('navigation_systems')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('navigation_systems'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('departments_show') || _vm.userCan('departments_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-divisions_organisation"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'divisions_organisation' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('divisions_organisation'))+" "),(_vm.isActiveHotSpot('divisions_organisation')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('divisions_organisation'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-reason_for_transferring_kp"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'reason_for_transferring_kp' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('reason_for_transferring_kp'))+" "),(_vm.isActiveHotSpot('reason_for_transferring_kp')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('reason_for_transferring_kp'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),(_vm.userCan('localities_show') || _vm.userCan('localities_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-localities_area"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'localities_area' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('localities_area'))+" "),(_vm.isActiveHotSpot('localities_area')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('localities_area'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('municipality_show') || _vm.userCan('municipality_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-municipal_association"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'municipal_association' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('municipal_association'))+" "),(_vm.isActiveHotSpot('municipal_association')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('municipal_association'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('subcategory_show') || _vm.userCan('subcategory_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-sub_category"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'sub_category' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('sub_category'))+" "),(_vm.isActiveHotSpot('sub_category')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('sub_category'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('inspectors_show') || _vm.userCan('inspectors_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-incpectors_district"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'incpectors_district' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('incpectors_district'))+" "),(_vm.isActiveHotSpot('incpectors_district')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('incpectors_district'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('drivers_show'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"reference-drivers"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'drivers' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('drivers'))+" "),(_vm.isActiveHotSpot('drivers')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('drivers'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }