<template>
<div class="user-profile">
   <div class="user-profile__header">
      <button 
         @click="$emit('closeModalUser')"
         @mouseenter="isHoverArrowBack = true"
         @mouseleave="isHoverArrowBack = false"
         class="user-profile__header__arrow" 
      >
         <img :src="require(`@/assets/restyle/images/users/icons/${isHoverArrowBack ? 'arrow_back__active' : 'arrow_back' }.svg`)" alt="">
      </button>
      <div class="user-profile__header__profile">
         <span class="user-profile__header__profile__text">
            Профиль
         </span>
         <div class="user-profile__header__profile__buttons-wrapper">
            <button 
               v-if="userCan('admin') || userCan('superadmin')" 
               @click="isOpenModalUserEdit"
               @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                  hoverEvent: $event,
                  tooltipPosition: 'bottom',
                  tooltipText: 'Редактировать профиль',
               })"
               @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"
            >
               <img style="height: 25px;" src="@/assets/restyle/images/users/icons/pencil.svg" alt="pencil">
            </button>
            <button
               @click="logout(); $store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"
               @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                  hoverEvent: $event,
                  tooltipPosition: 'bottom',
                  tooltipText: 'Выйти из системы',
               })"
               @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"
            >
               <img style="height: 25px;" src="@/assets/restyle/images/users/icons/exit.svg" alt="exit">
            </button>
         </div>
      </div>
   </div>
   <div class="user-profile__content scrollbar scrollbar--primary">
      <div class="user-profile__middle">
         <div class="user-profile__middle__avatar">
            <img class="user-profile__middle__avatar__img" :src="avatar ? avatar : require('@/assets/icons/user/default_avatar.svg')" alt="avatar">
            <div style="display: flex; flex-direction: column; align-items: center;">
               <div class="user-profile__middle__avatar__update" @click="chooseAvatar()">
                  <input type="file" accept="image/*" @change="uploadAvatar" ref="uploadAvatarRef" hidden />
                  <button class="user-profile__middle__avatar__update__btn">
                     <img src="@/assets/restyle/images/users/icons/update_photo.svg" alt="">
                  </button>
                  <div class="user-profile__middle__avatar__update__text">Загрузить новое фото</div>
               </div>
               <div v-if="avatar" class="user-profile__middle__avatar__delete" @click="$emit('openDeletePhotoUserModal')">
                  <button class="user-profile__middle__avatar__delete__btn">
                     <img src="@/assets/restyle/images/users/icons/delete_photo.svg" alt="">
                  </button>
                  <div class="user-profile__middle__avatar__delete__text">Удалить фото</div>
               </div>
            </div>
         </div>
      </div>
         <div class="user-profile__info">
            <div class="user-profile__info__login">
               <div class="user-profile__info__login__title">
                  Логин
               </div>
               <div class="user-profile__info__login__value">
                  {{ currentUser.name }}
               </div>
            </div>
            <div class="user-profile__info__fio">
               <div class="user-profile__info__fio__title">
                  ФИО
               </div>
               <div v-if="isVisibleEditFio === false" class="user-profile__info__fio__value">
                  {{ currentUser.full_name  }}
                  <button class="user-profile__info__fio__edit" @click.stop="isVisibleEditFio = true"
                  @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                  hoverEvent: $event,
                  tooltipPosition: 'top',
                  tooltipText: 'Редактировать ФИО',
                  })"
                  @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')">
                     <img src="@/assets/restyle/images/users/icons/pencil.svg" alt="pencil">
                  </button>
               </div>
               <div v-if="isVisibleEditFio"
               class="user-profile__info__fio__edit__custom-block">
                  <input class="user-profile__info__fio__edit__custom-input" type="text" v-model="newFullName">
                  <div class="user-profile__info__fio__edit__wrapper-buttons">
                     <button @mouseenter="isHoverEditFio = true"
                     @mouseleave="isHoverEditFio = false"
                     @click.stop="acceptFio()"
                     style="margin-right: 10px;">
                        <img v-if="!isHoverEditFio" src="@/assets/restyle/images/users/icons/accept_fio.svg" alt="">
                        <img v-else src="@/assets/restyle/images/users/icons/accept_fio_hover.svg" alt="">
                     </button>
                     <button @mouseenter="isHoverCancelFio = true"
                     @mouseleave="isHoverCancelFio = false"
                     @click.stop="cancelFio">
                        <img v-if="!isHoverCancelFio" src="@/assets/restyle/images/users/icons/cancel_fio.svg" alt="">
                        <img v-else src="@/assets/restyle/images/users/icons/cancel_fio_hover.svg" alt="">
                     </button>
                  </div>
               </div>
            </div>
            <div class="user-profile__info__email">
               <div class="user-profile__info__email__title">
                  Email
               </div>
               <div class="user-profile__info__email__value">
                  {{ currentUser.email }}
                     <button class="user-profile__header__email__btn-copy"
                        @click="copyEmail(currentUser.email)"
                        @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                        hoverEvent: $event,
                        tooltipPosition: 'top',
                        tooltipText: 'Копировать email',
                        })"
                     @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"
                     >
                     <img src="@/assets/restyle/images/users/icons/copy.svg">
                  </button>
               </div>
            </div>
         </div>
         <div class="user-profile__orgs">
            <div class="user-profile__orgs__title">
               Организации
            </div>
            <div v-for="(parentOrg, j) in currentUserOrgsSort">
               <div class="parent-org-wrapper">
                  <img
                  v-if="!parentOrg.parent_id"
                  src="@/assets/restyle/images/shared/icons/crown.svg"
                  class="org-crown-icon"
                  alt=""
                  />
                  <div
                  v-if="parentOrg.parent_id"
                  class="org-dot-icon-no-parent"
                  :class="{
                     'ml-15': isHasParents(parentOrg.parent_id)
                  }"
               >
               </div>
               <span
                  class="parent-org-text-wrapper"
               >
                  <span class="org-name">{{ parentOrg.name }}</span>
                  <span class="org-id">[{{ parentOrg.id }}]</span>
                  </span>
               </div>
               <template v-if="parentOrg.children">
                  <div class="children-org-wrapper" v-for="(childrenOrg, k) in parentOrg.children">
                  <div
                     class="org-dot-icon"
                  ></div>
                  <span
                     class="children-org-text-wrapper"
                  >
                     <span class="org-name">{{ childrenOrg.name }}</span>
                     <span class="org-id">[{{ childrenOrg.id }}]</span>
                  </span>
                  </div>
               </template>
            </div>
         </div>
         <div class="user-profile__roles">
            <div class="user-profile__roles__title">
               Роли <span style="color: white; font-size: 18px;">Факта</span>
            </div>
            <div class="user-profile__roles__wrapper">
               <div class="user-profile__roles__value" v-for="(role, idx) in currentRoles(currentUser.roles)" :key="idx">
                  <div class="user-profile__roles__value__point__green"
                  @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                  hoverEvent: $event,
                  tooltipText: 'Роль в Факте',
                  })" 
                  @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"/>
                  {{ role?.name }}
               </div>
            </div>
         </div>
         <div class="user-profile__roles">
            <div class="user-profile__roles__title">
               Роли <span style="color: white; font-size: 18px;">Логистики</span>
            </div>
            <div class="user-profile__roles__wrapper">
               <div class="user-profile__roles__value" v-for="(srpRole, idx) in currentUser.role_srp_ids" :key="idx">
                  <div class="user-profile__roles__value__point__blue"@mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                  hoverEvent: $event,
                  tooltipText: 'Роль в Логистике',
                  })" 
                  @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP')"/>
                  {{ srp_name(srpRole)?.name }}
               </div>
            </div>
         </div>
         <div class="user-profile__footer">
               <button v-if="userCan('admin') || userCan('superadmin')" class="user-profile__footer__change-password" @click="$emit('openChangePasswordModal')">
                  <img src="@/assets/restyle/images/users/icons/change_pasword.svg" alt="change_pasword">
                  Сменить пароль
               </button>
               <div v-else/>
               <div class="user-profile__footer__create">
                  Создан <br/>
                  {{ getDateTime(currentUser.created_at) }}
               </div>
         </div>
      </div>
   </div>
</template>

<script>
import { request } from "@/_helpers";
import { mapActions, mapGetters } from 'vuex';
import { wbConnect } from "@/_helpers/wb-connect.js";

   export default {
      name: 'User',
      data() {
         return {
            isHoverArrowBack: false,
            isVisibleEditFio: false,
            isHoverEditFio: false,
            isHoverCancelFio: false,
            newFullName: '',
         }
      },
      computed: {
         ...mapGetters({
            user: 'user/user',
            avatar: 'avatar/avatar',
            userOrganisations: 'user/userOrganisations',
            roles: 'roles/allRoles',
            rolesSRP: 'roles/roles_SRP',
            currentRoles: 'roles/currentRoles',
            userCan: 'user/userCan',
            currentUser: 'user/currentUser',
         }),
         currentUserOrgsSort() {
            const uniqueItems = new Set();
            const sortedArr = this.currentUser.organisations.reduce((acc, item) => {
               if (!uniqueItems.has(item.id)) {
                  acc.push(item);
                  uniqueItems.add(item.id);
               }
               const children = this.currentUser.organisations.filter(el => el.parent_id === item.id);
               children.forEach(child => {
                  if (!uniqueItems.has(child.id)) {
                     acc.push(child);
                     uniqueItems.add(child.id);
                  }
               });
               return acc;
            }, []);
            return sortedArr;
         }
      },
      created() {
         this.newFullName = this.currentUser.full_name;
         this.$store.dispatch('organisations/all');
         this.$store.dispatch('organisations/all_tree');
      },
      methods: {
         srp_name(v) {
            let result = this.rolesSRP.filter(item => item.id === parseInt(v));
            return (result) ? result[0] : null
         },
         getDateTime(inputDateTime) {
            return new Date(inputDateTime).toLocaleString().replace(',', '');;
         },
         isOpenModalUserEdit() {
            this.$emit('isOpenModalUserEdit');
            this.$emit('closeModalUser');
            this.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');
         },
         logout() {
            const userID = JSON.parse(localStorage.getItem('user')).id;
            wbConnect().echoCloseChannel(`notification-${userID}`);
            this.$store.commit('user_notifications/SET_IS_VISIBLE_NOTIFICATIONS_POPUP', false);
            this.$store.commit('user_notifications/CLEAN_LIST_NOTIFICATIONS', false);
            this.$store.dispatch("auth/logout");
            this.$router.push({ name: "login" });
         },
         copyEmail(email) {
            navigator.clipboard.writeText(email)
               .then(() => {
                  this.$store.dispatch('alert/alerts', { type: 'success', message: 'Email скопирован' });
               })
               .catch(() => {
                  this.$store.dispatch('alert/alerts', { type: 'danger', message: 'Копирование не удалось' });
               })
            },
            cancelFio() {
               this.isVisibleEditFio = false;
               this.newFullName = this.currentUser.full_name;
            },
            acceptFio() {
               if (this.newFullName === this.currentUser.full_name) {
                  this.isVisibleEditFio = false;
               } else {
                  this.sendNewFio();
               }
            },
         sendNewFio() {
            request(`${process.env.VUE_APP_AUTH_URL}/user`, 'put', { full_name: this.newFullName })
               .then((res) => {
                  if (res.success) {
                  this.$store.dispatch('alert/alerts', { type: 'success', message: 'ФИО изменено' });
                  this.isVisibleEditFio = false;
                  this.$emit('update')
                  } else {
                  this.$store.dispatch('alert/alerts', { type: 'danger', message: res.message });
                  }
               })
            },
         chooseAvatar() {
            const uploadAvatarButton = this.$refs.uploadAvatarRef;
            uploadAvatarButton.click();
         },
         uploadAvatar() {
            const avatarFile = this.$refs.uploadAvatarRef.files[0];
            if (!avatarFile) {
               return;
            }
            if (avatarFile.size > 2000000) {
               this.$store.dispatch('alert/alerts', { type: 'danger', message: 'Размер файла превышает 2мб. Попробуйте загрузить другой файл.' });
               return;
            }
            if (!avatarFile.type?.includes('image')) {
               this.$store.dispatch('alert/alerts', { type: 'danger', message: 'Неверный тип файла. Выберите изображение.' });
               return;
            }
            const formData = new FormData();
            formData.append('avatar', avatarFile);
            this.updateAvatar(formData);
         },
         updateAvatar(image) {
            if (this.currentUser?.id) {
               request(`${process.env.VUE_APP_WN}/auth/avatar/update`, 'post', image )
                  .then(() => {
                  this.$store.dispatch('avatar/getAvatar');
                  this.$store.dispatch(
                     "alert/alerts",
                     { message: 'Аватар обновлён.', type: "success" },
                     );
                  })
               }
         },
         isHasParents(parrentID) {
            return this.currentUserOrgsSort.filter(item => item.id === parrentID)?.length;
         }
      }
   }
</script>

<style lang="scss" scoped>
.user-profile {
   position: fixed;
   top: 51px;
   right: 5px;
   height: 720px;
   width: 455px;
   background-image: linear-gradient(#135080,#1A828A , #135080);
   border-radius: 20px;
   color: white;
   font-size: 20px;
   overflow: hidden;
   &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid white;
      padding-top: 13px;
      padding-bottom: 13px;
      &__profile {
         display: flex;
         align-items: center;
         margin-left: 200px;
         &__text {
            font-size: 28px;
            margin-left: -40px;
            margin-right: 90px;
         }
         &__buttons-wrapper {
            display: flex;
            column-gap: 10px;
            padding-right: 10px;
         }
      }
      &__arrow {
         width: 20px;
         position: absolute;
         left: 30px;
         top: 50%;
         transform: translateY(-50%);
      }
      &__email {
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         &__text {
            font-size: 28px;
            margin-right: 12px;
         }
         &__btn-copy {
            width: 17px;
            margin-left: 5px;
         }
      }
   }
   &__content {
      padding: 20px 30px 25px 30px;
      max-height: 590px;
      overflow-y: auto;
   }
   &__middle {
      margin-bottom: 25px;
      &__top {
         display: flex;
         align-items: flex-end;
         justify-content: space-between;
         &__left {
            display: flex;
            align-items: center;
            cursor: pointer;
            & button {
               margin-left: 20px;
               & img {
                  width: 26px;
                  height: 26px;
               }
            }
            &__text {
               font-size: 38px;
               display: inline-block;
               margin: 0;
               &:hover {
                  text-decoration: underline;
               }
            }
         }
         &__right {
            display: flex;
            align-items: center;
            cursor: pointer;
            & button {
               margin-left: 10px;
               & img {
                  width: 16px;
               }
            }
            &__text {
               font-size: 16px;
               display: inline-block;
               margin: 0;
               &:hover {
                  text-decoration: underline;
               }
            }
         }
      }
      &__avatar {
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
         width: 280px;
         height: 180px;
         border: 1px solid white;
         border-radius: 25px;
         margin: 0 auto;
         &__img {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            margin-bottom: 15px;
            margin-top: 10px;
         }
         &__update {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &__text {
               cursor: pointer;
               font-size: 18px;
               &:hover {
                  text-decoration: underline;
               }
            }
            &__btn {
               margin-right: 10px;
               & img {
                  width: 20px;
               }
            }
         }
         &__delete {
            display: flex;
            align-items: center;
            margin-top: -10px;
            &__text {
               cursor: pointer;
               font-size: 16px;
               color: #FFB6B6;
               &:hover {
                  text-decoration: underline;
               }
            }
            &__btn {
               margin-right: 10px;
               & img {
                  width: 14px;
               }
            }
         }
      }
   }
   &__info {
      &__login {
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         &__title {
            font-size: 18px;
            color: #D1D9E6;
            min-width: 55px;
         }
         &__value {
            font-size: 20px;
            color: white;
            margin-left: 25px;
         }
      }
      &__fio {
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         &__title {
            font-size: 18px;
            color: #D1D9E6;
            min-width: 55px;
         }
         &__value {
            font-size: 20px;
            color: white;
            margin-left: 25px;
            cursor: pointer;
            &:hover {
               text-decoration: underline;
            }
         }
         &__edit {
            width: 18px;
            margin-left: 5px;
            position: relative;
            top: -2px;
            &__wrapper-buttons {
               position: absolute;
               right: 0;
               bottom: 8px;
            }
            &__custom-block {
               display: flex;
               align-items: center; position: relative;
               width: 100%;
               margin-left: 25px;
            }
            &__custom-input {
               border-bottom: 1px solid #44FF8F;
               padding-bottom: 5px;
               width: 100%;
               padding-right: 85px;
            }
         }
      }
      &__email {
         display: flex;
         align-items: center;
         margin-bottom: 5px;
         &__title {
            font-size: 18px;
            color: #D1D9E6;
            min-width: 55px;
         }
         &__value {
            font-size: 20px;
            color: white;
            margin-left: 25px;
         }
      }
   }
   &__orgs {
      margin-bottom: 10px;
      &__title {
         font-size: 18px;
         color: #D1D9E6;
      }
      &__value {
         font-size: 20px;
         color: white;
         margin-bottom: 10px;
      }
   }
   &__roles {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      &__title {
         font-size: 16px;
         color: #D1D9E6;
      }
      &__wrapper {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
      }
      &__value {
         display: inline-flex;
         align-items: center;
         font-size: 20px;
         color: white;
         margin-right: 15px;
         &__point {
            margin-right: 10px;
            &__green {
               height: 10px;
               width: 10px;
               border-radius: 50%;
               background-color: #44FF8F;
               margin-right: 5px;
            }
            &__blue {
               height: 10px;
               width: 10px;
               border-radius: 50%;
               background-color: #2BF2FF;
               margin-right: 10px;
            }
         }
      }
   }
   &__footer {
      padding: 0px 13px;
      display: flex;
      font-size: 15px;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      justify-content: space-between;
      &__change-password {
         display: flex;
         align-items: center;
         color: white;
         font-size: 16px;
         cursor: pointer;
         &:hover {
            text-decoration: underline;
         }
         & img {
            width: 22px;
            margin-right: 10px;
         }
      }
      &__create {
         text-align: right;
      }
   }
}

.parent-org-wrapper {
   display: flex;
   flex-wrap: nowrap;
   align-items: center;
}

.parent-org-text-wrapper, .children-org-text-wrapper {
   text-overflow: ellipsis;
   overflow: hidden;
   display: inline-block;
   white-space: nowrap;
}

.org-crown-icon {
   margin-right: 8px;
   width: 12px;
   height: 12px;
   position: relative;
   top: 1px;
   filter: brightness(0) invert(1);
}

.org-dot-icon {
   margin-left: 35px;
   margin-right: 8px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   display: inline-block;
   background: $color-page-text;
   flex-shrink: 0;
   filter: brightness(0) invert(1);
}

.org-dot-icon-no-parent {
   margin-right: 8px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   display: inline-block;
   background: $color-page-text;
   flex-shrink: 0;
   margin-left: 2px;
   filter: brightness(0) invert(1);
}

.ml-15 {
   margin-left: 15px;
}
</style>
