<template>
   <div class="popup" @click="resetTimer()">
      <div class="popup-container" ref="containerNotifications">
         <div class="popup-header">
            <div v-if="getListNotifications.length" class="popup-header__count">
               {{ getListNotifications.length }}
            </div>
            <div v-else />
            <div style="display: flex; align-items: center;">
               <button v-if="getListNotifications.length" class="popup-header__btn-clean" @click="cleanBTN()">
                  Очистить
               </button>
               <div class="popup-header__close-popup" @click="closePopup()">&times;</div>
            </div>
         </div>
         <div @scroll="resetTimer()" class="popup-content scrollbar scrollbar--primary">
            <div v-if="getListNotifications.length">
               <div class="notification" v-for="(notification) in getListNotifications" :key="notification.id">
                  <div v-if="notification.type === 'test'">
                     <h3 class="notification__title mb-0">
                        {{ notification.message }}!
                     </h3>
                     <p v-if="notification?.params?.error" class="notification__error">
                        {{ notification.params.error }}
                     </p>
                  </div>
                  <div v-if="notification.type === 'processing_file'">
                     <h3 class="notification__title">
                        {{ notification.message }}
                     </h3>
                     <div class="notification__bottom-info">
                        <div class="notification__bottom-info__progress-bar">
                        <b-progress :max="100">
                           <b-progress-bar :value="notification?.params.progress" striped :animated="notification?.params.progress === 100 ? false : true"/>
                        </b-progress>
                        </div>
                        <button v-if="notification?.params.progress === 100"
                        @click="download(notification.params.link)"
                        class="notification__bottom-info__btn-download">
                           <em class="ti ti-download mr-2"/>
                           Скачать
                        </button>
                        <div v-else class="notification__bottom-info__percent">
                           {{ `${notification?.params.progress}%` }}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div v-else class="empty-notifications">
               Уведомлений нет.
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
   data() {
      return {
         interval: null,
         timerClose: 20000,
      }
   },
   created() {
      this.interval = setInterval(() => {
         if (this.timerClose >= 1000) {
            this.timerClose = this.timerClose - 1000;
         } else {
            this.setIsVisibleNotPopup(false);
            this.setDefaultPositionForAlert();
            clearInterval(this.interval);
         }
      }, 1000)
   },
   beforeDestroy() {
      this.setDefaultPositionForAlert()
   },
   methods: {
      ...mapMutations({
         setIsVisibleNotPopup: "user_notifications/SET_IS_VISIBLE_NOTIFICATIONS_POPUP",
         cleanListNotifications: "user_notifications/CLEAN_LIST_NOTIFICATIONS",
      }),
      resetTimer() {
         this.timerClose = 20000;
      },
      download(link) {
         window.location.href = link
      },
      closePopup() {
         this.setIsVisibleNotPopup(false);
         this.setDefaultPositionForAlert();
      },
      setDefaultPositionForAlert() {
         const globalRestyleAlert = document.querySelector('.restyle-alert');
         if (globalRestyleAlert) {
            globalRestyleAlert.style.bottom = '46px';
         }
      },
      cleanBTN() {
         this.cleanListNotifications();
      }
   },
   computed: {
      ...mapGetters({
      getListNotifications: "user_notifications/GET_LIST_NOTIFICATIONS",
      isWaitAlertAfterRequest: "user_notifications/GET_IS_WAIT_ALERT_AFTER_REQUEST"
      }),
   }
}
</script>

<style lang="scss" scoped>
.popup-container {
   position: fixed;
   top: 52px;
   right: 52px;
   overflow: hidden;
   max-height: 400px;
   width: 425px;
   background-color: #1D407B;
   color: white;
   padding: 10px;
   border: 1px solid $color-link;
   border-radius: 8px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   z-index: 9999;
}

.popup-content {
   max-height: 380px;
   overflow-y: auto;
   width: 100%;
   padding-bottom: 30px;
}

.popup-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 10px;
   &__count {
      color: white;
      font-size: 18px;
      font-weight: bold;
   }
   &__btn-clean {
      cursor: pointer;
      padding: 10px 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      height: 26px;
      background: transparent;
      border-radius: 50px;
      border: 1px solid #110c61;
      background-color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 27px;
      color: #110c61;
      transition: all 0.3s ease;
      margin-right: 10px;
      &:hover {
         background-color: #44ff8f;
         color: #110c61;
      }
   }
   &__close-popup {
      cursor: pointer;
      font-size: 20px;
      color: white;
      &:hover {
         font-weight: bold;
      }
   }
}

.notification {
   border: 1px solid black;
   border-radius: 15px;
   padding: 10px;
   margin-bottom: 10px;
   color: #110c61;
   background-color: white;
   &:last-child {
      margin-bottom: 0;
   }
   &__title {
      font-size: 14px;
   }
   &__error {
      color: red;
      margin-bottom: 0;
   }
   &__bottom-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__progress-bar {
         width: 266px;
         margin: auto 0;
         margin-right: 10px;
         height: 10px;
      }
      &__btn-download {
         padding: 10px 20px;
         position: relative;
         display: inline-flex;
         align-items: center;
         justify-content: center;
         width: 105px;
         height: 28px;
         background: #44ff8f;
         border-radius: 50px;
         cursor: pointer;
         border: 3px solid transparent;
         font-weight: 700;
         font-size: 13px;
         line-height: 27px;
         color: #110c61;
         transition: all 0.3s ease;
         margin-left: auto;
         &:hover {
            background-color: #110c61;
            color: #44ff8f;
         }
      }
      &__percent {
         color: #110c61;
         font-size: 16px;
         font-weight: bold;
         margin-bottom: 3px;
         margin-right: auto;
      }
   }
}
.empty-notifications {
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   font-size: 16px;
}
</style>

<style lang="scss">

</style>

